<template>
    <!-- CHECKLIST -->
    <v-container  class="pa-2 my-3" fluid>
        <div slot="label"> 
            {{ label }}
            <v-tooltip location="bottom" v-if="tooltip">
                <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">fas fa-question-circle</v-icon>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </div>
        <div v-if="items && items.length > 10">
            <v-chip-group>
                <v-chip label @click="select(true)">Select All</v-chip>
                <v-chip label @click="select(false)">Unselect All</v-chip>
            </v-chip-group>
        </div>
        <span v-for="(n, index) in items" :key="n.name">
            <span v-if="n.seperator && (index == 0 || (items[index-1] && items[index-1].seperator != n.seperator))">
                <v-divider :thickness="3">{{ n.seperator }}</v-divider>
            </span>
            <v-checkbox v-model="checkedBoxes" :label="n.name" :value="n.value"></v-checkbox>
        </span>
        <div v-if="items && items.length > 10">
            <v-chip-group>
                <v-chip label @click="select(true)">Select All</v-chip>
                <v-chip label @click="select(false)">Unselect All</v-chip>
            </v-chip-group>
        </div>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                checkedBoxes: []
            }
        },
        props: {
            label: {
                type: String
            },
            tooltip: {
                type: String
            },
            items: {
                type: Array
            },
            selected: {
                type: Array
            }
        },
        methods: {
            select(state) {
                if (state) {
                    this.checkedBoxes = this.items.map(({ value }) => value)
                } else {
                    this.checkedBoxes = []
                }
            },
        },
        watch: {
            selected: {
                handler (val) {
                    this.checkedBoxes = val
                }
            },
            checkedBoxes: {
                handler (val) {
                    this.$emit('checked', val)
                }
            }
        }
    }
</script>