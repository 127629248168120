<template>
  <div>
    <h5 v-if="form.header" class="text-h5 pa-2">{{ form.header }}</h5>
    <p v-if="form.description" class="text-body-1 pa-2">{{ form.description }}</p>
    <v-container style="margin-bottom: 30px" :class="{ 'pa-10': !mobile}">
      <v-card :density="mobile ? 'compact' : 'comfortable'">
        <BaseFormValidationSummary :validationErrors="formErrors" :class="{ 'mb-5': !mobile }">
        </BaseFormValidationSummary>
        <v-row no-gutters>
          <template v-for="(formItem, index) in formDesign">
            <v-col :cols="mobile ? 12 : (formItem.cols ? formItem.cols : 12)"
              v-if="showFormComponentAndSetDefault(formItem)" :key="index">
              <!-- TEXTFIELD / PASSWORD -->
              <template v-if="formItem.type === 'text' || formItem.type === 'password' || !formItem.type">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton input"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseTextField :label="formItem.label" :class="{ 'text-error': formErrors[formItem.name]}"
                    :defaultValue="data[formItem.name] ? data[formItem.name] : null" :readonly="formItem.readonly"
                    :type="formItem.type" @valueEntered="data[formItem.name] = $event"></BaseTextField>
                  <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                    variant="tonal"></v-alert>
                </v-alert>
              </template>

              <!-- DATETIME -->
              <template v-if="formItem.type === 'datetime'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseTextField :label="formItem.label"
                    :defaultValue="data[formItem.name] ? data[formItem.name] : null" :readonly="true"></BaseTextField>
                </v-alert>
              </template>

              <!-- TEXTAREA -->
              <template v-if="formItem.type === 'textarea'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseTextArea :label="formItem.label" :rows="formItem.size"
                    :defaultValue="data[formItem.name] ? data[formItem.name] : null" :readonly="formItem.readonly"
                    :class="{ 'text-error': formErrors[formItem.name]}" @valueEntered="data[formItem.name] = $event">
                  </BaseTextArea>
                  <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                    variant="tonal"></v-alert>
                </v-alert>
              </template>

              <!-- BANNER -->
              <template v-if="formItem.type === 'banner'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" 
                  :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" 
                  density="compact"
                  class="" 
                  :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseBanner
                    :route="formItem.route"
                    :bannerIcon="formItem.icon"
                    :bannerLabel="formItem.label"
                    :tooltipText="formItem.tooltip"
                    :bannerText="data[formItem.name] ? data[formItem.name] : null"
                    :class="{ 'text-error': formErrors[formItem.name]}"
                    v-model="data[formItem.value]"
                  ></BaseBanner>
                </v-alert>
              </template>

              <!-- AUTOCOMPLETE -->
              <template v-if="formItem.type === 'autocomplete'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton input"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseAutoComplete
                    :formItem="formItem"
                    :formUrl="urls[formItem.name]"
                    :defaultValue="data[formItem.name] ? data[formItem.name] : null"
                    :class="{ 'text-error': formErrors[formItem.name]}"
                    @formSelection="data[formItem.name] = $event"
                    @error="error"
                    @suggestion="onAutocompleteSuggestion"
                  ></BaseAutoComplete>
                  <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                    variant="tonal"></v-alert>
                </v-alert>
              </template>

              <!-- JSON -->
              <template v-if="formItem.type === 'json'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseRenderJson :label="formItem.label"
                    :jsonObject="data[formItem.name] ? data[formItem.name] : null"></BaseRenderJson>
                </v-alert>
              </template>

              <!-- MULTI SELECTION CHECKLIST -->
              <template v-if="formItem.type === 'checklist'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseCheckList :label="formItem.label" :tooltip="formItem.tooltip" :readonly="formItem.readonly"
                    :items="formItem.list" :selected="checkboxes[formItem.name]"
                    @checked="data[formItem.name] = $event"></BaseCheckList>
                </v-alert>
              </template>

              <!-- RADIO BUTTONS -->
              <template v-if="formItem.type === 'list'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseRadioButtons :label="formItem.label" :tooltip="formItem.tooltip" :readonly="formItem.readonly"
                    :items="formItem.list" :defaultValue="data[formItem.name]" @checked="data[formItem.name] = $event">
                  </BaseRadioButtons>
                </v-alert>
              </template>

              <!-- CLICKABLE LIST -->
              <template v-if="formItem.type === 'clicklist'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert :border="formItem.highlight ? 'start' : false" border-color="primary accent-4" class=""
                  :elevation="formItem.highlight ? '2' : '0'" :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseClickList v-show="!loading" :label="formItem.label" :tooltip="formItem.tooltip"
                    :items="formItem.list" @dialog="dialog"></BaseClickList>
                </v-alert>
              </template>

              <!-- TOGGLE -->
              <template v-if="formItem.type === 'toggle'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseToggle :label="formItem.label" :tooltip="formItem.tooltip" :readonly="formItem.readonly"
                    :onValue="formItem.onValue" :offValue="formItem.offValue"
                    :defaultValue="data[formItem.name] ? data[formItem.name] : null"
                    @checked="data[formItem.name] = $event">
                  </BaseToggle>
                </v-alert>
              </template>

              <!-- BUTTON -->
              <template v-if="formItem.type === 'button'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseButton :label="formItem.label" :icon="formItem.icon" :readonly="formItem.readonly"
                    :loading="buttonPushed[index]" @clicked="buttonPush(index, formItem, data[formItem.name])">
                  </BaseButton>
                </v-alert>
              </template>

              <!-- TABLE -->
              <template v-if="formItem.type === 'table'">
                <BaseTable :headers="formItem.headers" :rows="data[formItem.name] ? data[formItem.name] : null"
                  :addButtonUrl="url(formItem.add, data, { 'add': true })" :deleteButtonUrl="formItem.delete"
                  :saveToRecent="form.header" :showCachedDataButton="false" :label="formItem.label" :controls="false"
                  :total="data[formItem.name] ? data[formItem.name].length : 0" @dialog="dialog" @error="error">
                </BaseTable>
              </template>

              <!-- NOTE -->
              <template v-if="formItem.type === 'note'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseNote v-show="!loading" :label="formItem.label" :message="data[formItem.name]"
                    :type="formItem.level"></BaseNote>
                </v-alert>
              </template>

              <!-- SELECT -->
              <template v-if="formItem.type === 'select'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <BaseSelect v-show="!loading" :label="formItem.label" :items="formItem.list"
                    :readonly="formItem.readonly" :defaultValue="data[formItem.name]"
                    :class="{ 'text-error': formErrors[formItem.name] }" @selected="data[formItem.name] = $event">
                  </BaseSelect>
                  <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                    variant="tonal"></v-alert>
                </v-alert>
              </template>

              <!-- LINK -->
              <template v-if="formItem.type === 'link' && formItem.route">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-container v-show="!loading" class="pa-2 my-3" fluid>
                  <v-btn @click="go(url(formItem.route, data[formItem.name]))"
                    :prepend-icon="formItem.icon ? 'fas ' + formItem.icon : ''">
                    {{ formItem.label }}
                  </v-btn>
                </v-container>
              </template>

              <!-- QRCODE -->
              <template v-if="formItem.type === 'qrcode'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-container v-show="!loading" class="pa-ma-0" fluid>
                  <div class="text-h6 mb-1">{{ formItem.label }}</div>
                  <v-img :key="makeImageShow" :src="data[formItem.name]" max-width="320" max-height="320"
                    :alt="formItem.label">
                  </v-img>
                </v-container>
              </template>

              <!-- DOWNLOAD -->
              <template v-if="formItem.type === 'download'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-container v-show="!loading" class="pa-ma-0" fluid>
                  <a v-on:click="go(url(formItem.route, data[formItem.name]), formItem.filename)">
                    <span>
                      <v-icon v-if="formItem.icon">fas {{ formItem.icon }} </v-icon> {{ formItem.label }}
                    </span>
                  </a>
                </v-container>
              </template>

              <!-- DATE -->
              <template v-if="formItem.type === 'date'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <v-container class="pa-2 my-3" fluid>
                    <BaseDatePicker v-model="data[formItem.name]" @update:output-value="data[formItem.name] = $event"
                      :label="formItem.label" :read-only="formItem.readonly"
                      :class="{'text-error': formErrors[formItem.name]}"></BaseDatePicker>
                    <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                      variant="tonal"></v-alert>
                  </v-container>
                </v-alert>
              </template>

              <!-- TIME -->
              <template v-if="formItem.type === 'time' && data[formItem.name] && data[formItem.name].length > 0">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert v-show="!loading" :border="formItem.highlight ? 'start' : false"
                  border-color="primary accent-4" class="" :elevation="formItem.highlight ? '2' : '0'"
                  :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <v-container class="pa-2 my-3" fluid>
                    <div class="text-h6 mb-1">{{ formItem.label }}</div>
                    <!-- eslint-disable-next-line vuetify/no-deprecated-components -->
                    <v-time-picker v-model="data[formItem.name]" class="mt-4" format="24hr" :use-seconds="true"
                      :class="{'text-error': formErrors[formItem.name]}"></v-time-picker>
                    <v-alert class="" v-if="formErrors[formItem.name]" :text="formErrors[formItem.name]" type="error"
                      variant="tonal"></v-alert>
                  </v-container>
                </v-alert>
              </template>

              <!-- SWITCH ON/OFF -->
              <template v-if="formItem.type === 'switch'">
                <v-skeleton-loader v-show="loading" type="text" class="custom-input-skeleton"></v-skeleton-loader>
                <v-alert :border="formItem.highlight ? 'start' : false" border-color="primary accent-4" class=""
                  :elevation="formItem.highlight ? '2' : '0'" :variant="formItem.highlight ? 'outlined' : 'plain'">
                  <v-container v-show="!loading" class="pa-2 my-3" fluid>
                    <v-switch v-model="data[formItem.name]" :label="formItem.label"></v-switch>
                  </v-container>
                </v-alert>
              </template>

              <!-- Output Debug Info, Useful when debugging or adding new form components -->
              <div v-if="formItem.debug">
                {{ formItem.name }}({{ formItem.value }}): {{ data[formItem.name] }}
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </v-container>
    <div v-if="!noButtons && !form.noactions && !loading" class="fab-container">
      <v-spacer></v-spacer>
      <v-btn :disabled="!valid" v-on:click="cancel()" variant="elevated" color="tonal" prepend-icon="fas fa-times"
        class="mr-2">Cancel</v-btn>
      <v-btn :disabled="!valid" v-on:click="save(addForm)" variant="elevated" color="primary" prepend-icon="fas fa-save"
        v-if="!addForm">Save</v-btn>
      <v-btn :disabled="!valid" v-on:click="save(addForm)" variant="elevated" color="primary" prepend-icon="fas fa-plus"
        v-if="addForm">Add</v-btn>
    </div>
  </div>
</template>

<style>
.fab-container {
  position: fixed;
  bottom: 30px;
  right: 60px;
  z-index: 2;
}
</style>

<script>
import { common } from '../mixins/common'
import ajax from '@/composables/useHttpCommon';
import { useDisplay } from 'vuetify';

export default {
  mixins: [common],
  data() {
    return {
      data: {},
      lookup: {},
      urls: [],
      addForm: false,
      loading: true,
      datePicker: [],
      checkboxes: {},
      buttonPushed: [],
      makeImageShow: "",
      header: '',
      description: '',
      formErrors: {},
      mobile: false
    }
  },
  props: {
    form: {
      type: Object
    },
    noButtons: {
      type: Boolean
    },
    submit: {
      type: Boolean
    }
  },
  computed: {
    valid: function () {
      if (typeof this.form.buttons !== "undefined") {
        if (this.form.buttons === false) {
          return false
        }
      }
      return true
    },
    pageUrl: function () {
      return this.url(this.form.url, this.id)
    },
    formDesign: function () {
      return JSON.parse(JSON.stringify(this.form.design))
    },
  },
  watch: {
    '$route': {
      handler() {
        this.loadPage()
        const { xs } = useDisplay();
        this.mobile = xs;
      },
      immediate: true
    },
    submit: {
      handler(val) {
        if (val && !this.loading) {
          this.save(this.addForm)
        }
      }
    }
  },
  methods: {
    onAutocompleteSuggestion(event) {
      /**
       * Autocomplete controls can "suggest" a value, based on the item selected within the control, to set to sibling controls.
       * E.g. "Hubspot Venue" has an autocomplete for prefilling "hubspot_id",
       * The autocomplete selected item object also has a property "name" - the value of which can be used as the "name" property value in the "add venue" view
       *  */ 

      const { value, target, applyWhen } = event;
      if (!this.data[target] || applyWhen === 'always') {
        this.data[target] = value;
      }
    },
    formIsValid(formData) {
      // Reset any previous errors
      this.formErrors = {};
      const requiredFields = this.formDesign.filter(field => field.required);

      requiredFields.forEach(requiredField => {
        if (formData[requiredField.value]) {
          // Field is required and present in the form submission, do nothing (?)
        } else {
          // console.log('Required field is not here!', requiredField);
          this.formErrors[requiredField.name] = `${requiredField.label} is a required field`;
        }
      })
      const hasErrors = Object.keys(this.formErrors).length > 0;

      if (hasErrors) {
        console.log('The form contains the following errors: ', this.formErrors)
      }

      return !hasErrors;
    },
    getId(formItem) {
      if (typeof this.data[formItem.id] !== 'object' && this.data[formItem.id]) {
        return this.data[formItem.id];
      } else if (typeof this.data[formItem.value] !== 'object' && this.data[formItem.value]) {
        return this.data[formItem.value];
      } else {
        return this.id
      }
    },
    loadPage() {
      if (this.$route.query.add == "true") {
        this.addForm = true
        this.$emit('addOrModify', 'add')
      } else {
        this.addForm = false
        this.$emit('addOrModify', 'modify')
      }
      this.checkUrlParameters()
      if (!this.addForm) {
        //
        // EDIT FORM
        //
        this.getFormData()

      } else {
        //
        // NEW FORM
        //
        this.setupNewForm()
      }
    },
    getFormData() {
      // Form Data
      if (typeof this.form.url !== 'undefined') {
        ajax.get(this.url(this.form.url, this.id), {})
          .then(response => {
            // reassign properties to the primary data object from the 
            // reponse in order to maintain reactivity
            for (var property in response.data) {
              // this.$set(this.data, property, response.data[property])
              this.data[property] = response.data[property];
            }
            // set up the form with any modifications to the data that is required
            this.setupModifyForm()
          })
          .catch(e => {
            this.error(e)
          })
      }
    },
    async setupModifyForm() {
      let index = 0
      for (var property in this.formDesign) {
        let formItem = this.formDesign[property]
        // if we don't have a name from the schema
        // we will use the value as a name, but
        // this is dangerous if the value is not
        // unique
        if (typeof formItem.name === "undefined") {
          formItem.name = formItem.value
        }
        // this.$set(this.data, formItem.name, this.convert(this.data[formItem.value]))
        this.data[formItem.name] = this.convert(this.data[formItem.value]);
        //
        // if our data value is actually an object, get the specified value 
        // of the object as our data value using the (hopefully) provided key
        // or alternatively the id
        //
        if (typeof this.data[formItem.value] === 'object' && this.data[formItem.value] !== null) {
          if (typeof formItem.key !== "undefined") {
            // this.$set(this.data, formItem.name, this.convert(this.data[formItem.value][formItem.key]))

            this.data[formItem.name] = this.convert(this.data[formItem.value][formItem.key]);

            //if (formItem.type != "list") {
            // if we are not populating a list
            // this.$set(this.data, formItem.name, this.data[formItem.value][formItem.key])
            //this.data[formItem.name] = this.data[formItem.value][formItem.key];
            // why do we rename the form item name to the key?
           // this.formDesign[property].name = formItem.key
          } else {
            // this.$set(this.data, formItem.name, this.convert(this.data[formItem.value][formItem.key]))
            // TODO: This sets the value to undefined ?!?!?!
            // this.data[formItem.name] = this.convert(this.data[formItem.value][formItem.key]);

          }
        } else if (this.data[formItem.value] && typeof this.data[formItem.value].id !== "undefined") {
          // why do we do this?
          //this.data[formItem.name] = this.convert(this.data[formItem.value].id);
        }
        //
        // autocomplete url
        //
        if (typeof formItem.autocomplete !== "undefined" && typeof formItem.autocomplete.url !== "undefined") {
          this.setAutoCompleteURL(formItem)
        }
        //
        // lookup the values from another resource
        //
        if (typeof formItem.lookup !== "undefined") {
          //
          // lookup values from another resource as long as the value has been provided
          //
          await this.setUpLookup(formItem)
        } 
        //else {
          // this.$set(this.data, formItem.name, this.convert(this.data[formItem.name], formItem.convert))
          //this.data[formItem.name] = this.convert(this.data[formItem.name], formItem.convert);
        //}
        //
        // checklists need special data formatting behavior
        //
        if (formItem.type == 'checklist') {
          if (this.data[formItem.name]) {
            // this.$set(this.checkboxes, formItem.name, this.data[formItem.name].map(function(element) {
            this.checkboxes[formItem.name] = this.data[formItem.name].map(function (element) {
              if (typeof element !== "object") {
                return element
              } else {
                return element.id ? element.id : element.value
              }
            });
          } else {
            // this.$set(this.checkboxes, formItem.name, [])
            this.checkboxes[formItem.name] = [];
          }
          if (formItem.lookup) {
            this.formDesign[property]['list'] = this.lookup[formItem.name];
          }
        }
        //
        // lists can be from the form design or built from service data
        //
        if (formItem.type == 'list' || formItem.type == 'clicklist') {
          if (typeof formItem.list == "undefined") {
            // this.$set(this.formDesign[property], 'list', new Array())
            this.formDesign[property]['list'] = new Array();
            // populate the formItem.list as best we can from the data
            if (typeof this.data[formItem.name] == "object") {
              for (var item in this.data[formItem.name]) {
                const newListItem = {}
                // determine name of list item
                if (typeof formItem.listNameKey !== "undefined") {
                  newListItem.name = this.data[formItem.name][item][formItem.listNameKey]
                } else if (typeof formItem.key !== "undefined") {
                  newListItem.name = this.data[formItem.name][item][formItem.key]
                } else if (typeof this.data[formItem.name][item].name !== "undefined") {
                  newListItem.name = this.data[formItem.name][item].name
                } else {
                  if (typeof item != "object") {
                    newListItem.name = String(this.data[formItem.name][item])
                  } else {
                    newListItem.name = String(this.data[formItem.name][item].id)
                  }
                }
                // determine value of list item
                if (typeof formItem.listValueKey !== "undefined") {
                  newListItem.value = this.data[formItem.name][item][formItem.listValueKey]
                } else if (typeof this.data[formItem.name][item].value !== "undefined") {
                  newListItem.value = this.data[formItem.name][item].value
                } else {
                  newListItem.value = this.data[formItem.name][item]
                }
                // set icon if neccesary
                if (this.data[formItem.name][item].icon) {
                  newListItem.icon = this.data[formItem.name][item].icon
                } else if (formItem.icon) {
                  newListItem.icon = formItem.icon
                }
                // set the path if neccesary
                if (formItem.route) {
                  newListItem.route = this.url(formItem.route, this.data[formItem.name][item])
                }
                this.formDesign[property]['list'].push(newListItem)
              }
            } else if (formItem.lookup) {
              this.formDesign[property]['list'] = this.lookup[formItem.name];
            }
            this.data[formItem.name] = null
          }
        }
        //
        // selects can use a range parameter to generate the values in the list
        //
        if (formItem.type == 'select') {
          if (typeof formItem.range == "object") {
            var min = 0
            if (typeof formItem.range.min == "number") {
              min = formItem.range.min
            }
            var max = 0
            if (typeof formItem.range.max == "number") {
              max = formItem.range.max
            }
            var step = 1
            if (typeof formItem.range.step == "number") {
              step = formItem.range.step
            }
            this.formDesign[property].list = []
            for (let current = min; current <= max; current = current + step) {
              const newListItem = {}
              newListItem.name = current
              newListItem.value = current
              this.formDesign[property].list.push(newListItem)
            }
          } else if (typeof formItem.list == "undefined") {
            if (formItem.lookup) {
              // this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
              this.formDesign[property]['list'] = this.lookup[formItem.name];
            }
          }
        }
        //
        // replace with lookup data when neccesary
        //
        if (formItem.type == 'banner' || formItem.type == 'json' || formItem.type == 'table') {
          if (formItem.lookup) {
            // this.$set(this.data, formItem.name, this.lookup[formItem.name])
            this.data[formItem.name] = this.lookup[formItem.name];
          }
        }
        //
        // attach an index to buttons
        //
        if (formItem.type == 'button') {
          // this.$set(this.buttonPushed, index, false)
          this.buttonPushed[index] = false;
        }
        //
        // datetimes should be formatted accordingly
        //
        if (formItem.type == 'datetime') {
          // this.$set(this.data, formItem.name, this.convert(this.data[formItem.name], 'datetime'))
          this.data[formItem.name] = this.convert(this.data[formItem.name], 'datetime');
        }
        //
        // load image data
        //
        if (formItem.type == 'qrcode') {
          formItem.payload = this.replaceParams(formItem.payload, this.data)
          this.getImage(formItem.name, formItem.value, formItem.payload, this.setImage)
        }
        index++
      }
      this.$emit('data', this.data)
      this.loading = false
    },
    async setupNewForm() {
      //
      // NEW FORM
      //
      for (var property in this.formDesign) {
        let formItem = this.formDesign[property]
        // skip auto-complete and data lookups on form items that are not to be used
        // on add forms
        if (!formItem.noadd) {
          // name if it has been provided
          if (typeof formItem.name === "undefined") {
            formItem.name = formItem.value
          }
          // this.$set(this.data, formItem.name, this.data[formItem.value])
          this.data[formItem.name] = this.data[formItem.value];
          //
          // lookup values from another resource as long as the value has been provided
          //
          if (typeof formItem.lookup !== "undefined") {
            await this.setUpLookup(formItem)
            if (formItem.type == 'select' && typeof formItem.list == "undefined") {
              this.formDesign[property]['list'] = this.lookup[formItem.name];
            }
          }
          //
          // checklists should default to empty
          //
          if (formItem.type == 'checklist') {
            // this.$set(this.checkboxes, formItem.name, [])
            this.checkboxes[formItem.name] = [];
            if (typeof formItem.list == "undefined") {
              if (formItem.lookup) {
                // this.$set(this.formDesign[property], 'list', this.lookup[formItem.name])
                this.formDesign[property]['list'] = this.lookup[formItem.name];
              }
            }
          }
          //
          // autocomplete url
          //
          if (typeof formItem.autocomplete !== "undefined" && typeof formItem.autocomplete.url !== "undefined") {
            this.setAutoCompleteURL(formItem)
          }
          //
          // populate ranges
          //                
          if (typeof formItem.range !== "undefined") {
            var min = 0
            if (typeof formItem.range.min == "number") {
              min = formItem.range.min
            }
            var max = 0
            if (typeof formItem.range.max == "number") {
              max = formItem.range.max
            }
            var step = 1
            if (typeof formItem.range.step == "number") {
              step = formItem.range.step
            }
            formItem.list = []
            for (let current = min; current <= max; current = current + step) {
              const newListItem = {}
              newListItem.name = current
              newListItem.value = current
              formItem.list.push(newListItem)
            }
          }
          // set defaults
          if (typeof formItem.default !== "undefined" && typeof formItem.default !== "object") {
            if (typeof formItem.list !== "undefined") {
              for (var item in formItem.list) {
                if (formItem.list[item].value == formItem.default) {
                  if (formItem.key) {
                    this.data[formItem.key] = formItem.list[item].value;
                    this.formDesign[property].value = formItem.key
                  }
                  this.data[formItem.name] = formItem.list[item].value;
                }
              }
            } else {
              this.data[formItem.name] = formItem.default;
            }
          }
        }
      }
      this.checkUrlParameters()
      this.loading = false
    },
    //
    // takes a form item configuration and populates data using the lookup responses
    //
    async setUpLookup(config) {
      var lookupItems = []
      if (config.lookup.constructor !== Array) {
        lookupItems.push(config.lookup)
      } else {
        lookupItems = config.lookup
      }
      var promises = new Array()
      for (var index in lookupItems) {
        if (typeof lookupItems[index].url !== "undefined") {
          let lkUrl = ""
          if (typeof config.id !== "undefined") {
            lkUrl = this.url(lookupItems[index].url, this.data[config.id])
          } else if (typeof this.data[config.name] !== "undefined" && typeof this.data[config.name] !== 'object') {
            lkUrl = this.url(lookupItems[index].url, this.data[config.name])
          } else {
            lkUrl = this.url(lookupItems[index].url)
          }
          promises.push(this.lookupData(lkUrl, lookupItems[index].return, config.convert))
        }
      }
      return Promise.all(promises).then((values) => {
        var responses = values.shift()
        while (values.length > 0) {
          let a_response = values.shift()
          if (a_response.length > 0) {
            responses = responses.concat(a_response)
          }
        }
        // this.$set(this.data, config.name, responses)
        // this.data[config.name] = responses;
        this.lookup[config.name] = responses;
      });
    },
    //
    // give a url, a returm schema, conversion rule
    // return lookup data
    //
    async lookupData(url, schema, convert) {
      return ajax.get(url, {})
        .then(response => {
          if (response.data.length && response.data.length > 0) {
            // look up response is an array or object and not empty
            let lookupArray = []
            for (let index in response.data) {
              if (typeof schema == 'object') {
                // build a lookup response object from provided schema
                var lookupResponse = {}
                for (let key in schema) {
                  // only add non-empty values
                  let found_value = response.data[index][schema[key]]
                  if (typeof found_value === "string") {
                    if (found_value.length !== 0) {
                      lookupResponse[key] = found_value
                    }
                  } else {
                    lookupResponse[key] = found_value
                  }
                }
                if (Object.keys(lookupResponse).length > 0) {
                  lookupArray.push(lookupResponse)
                }
              } else {
                // what is return schema then?
                lookupArray.push(this.convert(response.data[index], convert))
              }
            }
            return lookupArray
          } else {
            if (typeof response.data == 'object' && typeof schema !== 'undefined') {
              // lookup data is an object, return the property we are looking for
              return this.convert(response.data[schema], convert)
            } else {
              console.log("lookup response is a bit weird " + response.data)
              return response.data
            }
          }
        })
        .catch(e => {
          this.error(e)
        })
    },
    getImage(name, url, payload, callback) {
      ajax.post(url, payload, { responseType: 'blob' })
        .then(function (response) {
          let reader = new FileReader();
          reader.onload = e => callback(name, e.target.result)
          reader.readAsDataURL(response.data);
        });
    },
    setImage(name, data) {
      this.makeImageShow = name
      this.data[name] = data
    },
    showFormComponentAndSetDefault(formItem) {
      if (formItem) {
        const showComponent = this.showFormComponent(formItem)
        if (typeof formItem.default == "object") {
          if (this.data[formItem.name]) {
            if (typeof formItem.default.hide !== "undefined" && !showComponent) {
              // this.$set(this.data, formItem.name, formItem.default.hide)
              this.data[formItem.name] = formItem.default.hide;
            } else if (typeof formItem.default.show !== "undefined" && showComponent) {
              // this.$set(this.data, formItem.name, formItem.default.show)
              this.data[formItem.name] = formItem.default.show;
            } else if (typeof formItem.default.source !== "undefined") {
              this.data[formItem.name] = this.data[formItem.default.source];
            } else {
              console.log("Default settings for " + formItem.value + " are not right")
            }
          } else if (typeof formItem.default.source !== "undefined" && this.data[formItem.default.source]) {
            this.data[formItem.name] = this.data[formItem.default.source];
          }
        }
        return showComponent
      }
    },
    showFormComponent(formItem) {
      if (this.addForm) {
        if (formItem.readonly || formItem.noadd || formItem.type == 'datetime') {
          return false
        }
      } else {
        if (formItem.noedit) {
          return false
        }
      }
      return this.showIf(formItem, this.data)
    },
    setDatePicker(minMax, formItem) {
      if (typeof formItem.date !== "undefined") {
        if (typeof formItem.date.direction !== "undefined") {
          const today = new Date(Date.now());
          if (minMax == 'min' && formItem.date.direction == 'future') {
            return today.toISOString();
          }
          if (minMax == 'max' && formItem.date.direction == 'past') {
            return today.toISOString();
          }
        }
      }
      return null;
    },
    show(incoming) {
      alert(incoming)
    },
    buttonPush(index, formItem, value) {
      // this.$set(this.buttonPushed, index, true)
      this.buttonPushed[index] = true;

      // GET
      if (typeof formItem.url !== "undefined" || typeof formItem.get !== "undefined") {
        // a get request with parameters on the button push
        let url = this.url(formItem.url, value)
        ajax.get(url)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
            this.buttonPushed[index] = null;
          })
          .catch(e => {
            this.error(e)
            this.buttonPushed[index] = null;
          })
      
      // PATCH
      } else if (typeof formItem.patch !== "undefined") {
        // a patch request with parameters in the payload on the button push
        let url = this.url(formItem.patch, value)
        let payload = {}
        if (formItem.payload) {
          payload = JSON.parse(this.replaceParams(formItem.payload, this.data))
        }
        ajax.patch(url, payload)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
            this.buttonPushed[index] = null;
          })
          .catch(e => {
            this.error(e)
            this.buttonPushed[index] = null;
          })

      // PUT
      } else if (typeof formItem.put !== "undefined") {
        // a put request with parameters in the payload on the button push
        let url = this.url(formItem.put, value)
        let payload = {}
        if (formItem.payload) {
          payload = JSON.parse(this.replaceParams(formItem.payload, this.data))
        }
        ajax.put(url, payload)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
            this.buttonPushed[index] = null;
          })
          .catch(e => {
            this.error(e)
            this.buttonPushed[index] = null;
          })

      // POST
      } else if (typeof formItem.post !== "undefined") {
        // a post request with parameters in the payload on the button push
        let url = this.url(formItem.post, value)
        let payload = {}
        if (formItem.payload) {
          payload = JSON.parse(this.replaceParams(formItem.payload, this.data))
        }
        ajax.post(url, payload)
          .then(response => {
            if (typeof response.data.message != "undefined") {
              this.$emit('dialog', "Success!", response.data.message)
            } else if (typeof response.data != "undefined") {
              this.$emit('dialog', "Success!", response.data)
            } else {
              this.$emit('dialog', "Success!")
            }
            this.buttonPushed[index] = null;
          })
          .catch(e => {
            this.error(e)
            this.buttonPushed[index] = null;
          })

      // LINK
      } else if (typeof formItem.link !== "undefined") {
        // link/launch/go off to a new page when the button is clicked
        let url = this.url(formItem.link, value)
        // this.$set(this.buttonPushed, index)
        this.buttonPushed[index] = null;
        this.go(url)
      }

    },
    checkUrlParameters() {
      // this will insert the key/value pairs from the url into the data object
      this.data['id'] = this.id;
      for (var key in this.$route.query) {
        this.data[key] = this.$route.query[key];
      }
    },
    setAutoCompleteURL(formItem) {
      let acURL
      if (formItem.autocomplete.id) {
        acURL = this.url(formItem.autocomplete.url, this.data[formItem.autocomplete.id])
      } else if (formItem.id) {
        acURL = this.url(formItem.autocomplete.url, this.data[formItem.id])
      } else if (formItem.autocomplete.filter) {
        acURL = this.url(formItem.autocomplete.url, this.data[formItem.autocomplete.filter])
      } else {
        acURL = this.url(formItem.autocomplete.url)
      }
      // this.$set(this.urls, formItem.name, acURL)
      this.urls[formItem.name] = acURL;
    },
    add(url, data) {
      ajax.post(url, data)
        .then(response => {
          console.log(response.data)
          this.$emit('dialog', 'Added!', 'Added!', true)
        })
        .catch(e => {
          this.error(e)
        })
        .finally(() => {
            this.$emit('formSubmitted', true)
        })
    },
    update(url, data, type) {
      if (typeof type === "string" && type === "patch") {
        ajax.patch(url, data)
          .then(response => {
            console.log(response.data)
            this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
          .catch(e => {
            this.error(e)
          })
          .finally(() => {
            this.$emit('formSubmitted', true)
          })
      } else if (typeof type === "string" && type === "post") {
        ajax.post(url, data)
          .then(response => {
            console.log(response.data)
            this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
          .catch(e => {
            this.error(e)
          })
          .finally(() => {
            this.$emit('formSubmitted', true)
          })
      } else {
        ajax.put(url, data)
          .then(response => {
            console.log(response.data)
            this.$emit('dialog', 'Updated!', 'Updated!', true)
          })
          .catch(e => {

            this.error(e)          })
          .finally(() => {
            this.$emit('formSubmitted', true)
          })
      }
    },
    save(add) {
      //
      // Save Form Data
      //
      var theData = {}
      for (var property in this.data) {
        // If there is a matching form item for this property and the form item passes the following criteria:
        // - not readonly
        // - not a datetime type (like created_at and updated_at)
        // - is allowed to be used for "adding" when "adding"
        // - is allowed to be used for "editting" when "editting"
        // - 
        // and not READONLY, copy the data onto the submitted request
        var match = this.form.design.find(item => {
          return (item.key === property || item.value === property || item.name === property)
        });
        if (match) {
          if (!match.readonly) {
            if ((!match.type || match.type != 'datetime')) {
              if ((add && !match.noadd) || (!add && !match.noedit)) {
                if (typeof this.data[property] !== "undefined" && this.data[property] !== null) {
                  if (match.key && typeof this.data[match.key] !== "undefined") {
                    // the 'key' has the data
                    theData[property] = this.data[match.key]

                  } else if (match.value && typeof this.data[property] !== "undefined") {
                    // the 'property' has the data
                    theData[property] = this.data[property]

                  } else if (match.name && typeof this.data[match.name] !== "undefined") {
                    // the 'name' has the data
                    theData[property] = this.data[match.name]

                  } else {
                    // the 'value' has the data
                    theData[property] = this.data[match.value]
                  }
                } else if (match.blankable) {
                  // This field has explicitly been configured to allow for empty values to be saved
                  theData[property] = '';
                }
              }
            }
          }
        }
      }

      if (this.formIsValid(theData)) {
        console.log('Form is valid');
      } else {
        console.log('Form is not valid, stopping form submission now');
        return;
      }
      this.$emit('dialog', 'Save', 'Saving...', 0)

      if (add) {
        var addUrl = ''
        if (typeof this.form.add === "undefined") {
          addUrl = this.url(this.form.url, this.data)
        } else {
          addUrl = this.url(this.form.add, this.data)
        }
        // replace {} in the url using the id and the form data 
        // before submitting the data to the url
        this.add(addUrl, theData)
      } else {
        var updateUrl = ''
        if (typeof this.form.patch !== "undefined") {
          updateUrl = this.url(this.form.patch, this.id)
          this.update(updateUrl, theData, "patch")
        } else if (typeof this.form.post !== "undefined") {
          updateUrl = this.url(this.form.post, this.id)
          this.update(updateUrl, theData, "post")
        } else if (typeof this.form.update !== "undefined") {
          updateUrl = this.url(this.form.update, this.id)
          this.update(updateUrl, theData, "put")
        } else {
          updateUrl = this.url(this.form.url, this.id)
            this.update(updateUrl, theData, "put")
          }
        }
      },
      cancel() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped>
.v-alert--variant-plain {
  opacity: 1;
}
.custom-input-skeleton :deep(.v-skeleton-loader__text) {
  height: 62px;
  border-radius: 2px;
}

.custom-input-skeleton.input :deep(.v-skeleton-loader__text) {
  height: 56px;
  border-radius: 2px;
}
</style>